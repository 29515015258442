import { CHANGE_ITEMS, DELETE_ITEM } from "./types";
import { apiPrefix } from './../../../helper'
import axios from 'axios'

export const changeItems = (items) => dispatch => {
    dispatch({
        type: CHANGE_ITEMS,
        payload: items,
    })
};

export const deleteItem = (id, uid = null, token=null) => dispatch => {
    dispatch({
        type: DELETE_ITEM,
        payload: id,
        token,
        uid
    })
};
