import { LOGIN, LOGOUT, REGISTER } from "./types";
import { apiPrefix, apiPrefixx } from "../../../helper";
import axios from 'axios'

export const loginUser = (user: Object) => async (
  dispatch: (arg0: { type: string; token: object }) => void
) => {
  try {
    let response = await axios.post(apiPrefixx + "login", user);
    let data: any = response.data;
    if (data.token) {
      dispatch({
        type: LOGIN,
        token: data.token
      });
      return { success: true }
    } else {
      return { error: data.message }
    }
  } catch (error) {
    return error;
  }

};

export const registerUser = (user: Object) => async (
  dispatch: (arg0: { type: string; payload: object; token: object }) => void
) => {
  try {
    let response = await fetch(apiPrefix + "register", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        // Accept: "application/json"
      },
      body: JSON.stringify({ user })
    });

    let data: any = response.json();

    if (data.success) {
      dispatch({
        type: REGISTER,
        payload: data.user,
        token: data.token
      });
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
};

export const logoutUser = () => (
  dispatch: (arg0: { type: string; payload: string }) => void
) => {
  dispatch({
    type: LOGOUT,
    payload: ''
  });
};
