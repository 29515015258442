import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiPrefix, baseTitle } from "../../helper";
import { Helmet } from "react-helmet";
import LineChart from "./../linechart/LineChart";
import DoughnutChart from "./../doughnut/DoughnutChart";
import Piechart from "./../piechart/Piechart";
import ReactWeather from "react-open-weather";
import "react-open-weather/lib/css/ReactWeather.css";
import { connect } from "react-redux";
import Layout from "./../Layout";
import { logoutUser } from "../login/actions/authActions";
import Chart from "react-apexcharts";
import PieChart from "react-apexcharts";

interface Props {
  token: any;
  location: any;
  logoutUser: () => any;
}

const TITLE = `${baseTitle} Analytics`;

class Analytics extends Component<Props, { chart: any, top_flowsOptions: any, top_flowsSeries: any, top_flow_pie_data: any, top_flow_pie_label: any }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      chart: null,
      top_flowsOptions: [],
      top_flowsSeries: [],
      top_flow_pie_data: [44, 55, 13, 33],
      top_flow_pie_label: ['Apple', 'Mango', 'Orange', 'Watermelon']
    };
    this.fetchCharts();
    this.getTopFlows();
    this.topicAnalytics();
  }

  topicAnalytics = async () => {
    try {
      let url = apiPrefix + "topicAnalytics";
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      console.log("Top flows response", response);
      if (response.data.success) {
        let top_flow_pie_data = [];
        let top_flow_pie_label = [];
        for(let row of response.data.data.groupData){
          top_flow_pie_label.push(row.complaintName);
          top_flow_pie_data.push(row.count);          
        }
        this.setState({ top_flow_pie_data, top_flow_pie_label});
        console.log("topicAnalytics", response.data.data.groupData);
      }
    } catch (error) { }
  };

  getTopFlows = async () => {
    try {
      let url = apiPrefix + "top-flows";
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      console.log("Top flows response", response);
      if (response.data.success) {
        let top_flowsOptions = []
        let top_flowsSeries = []
        response.data.data.top_flows.map((item) => {
          top_flowsOptions.push(item.name)
          top_flowsSeries.push(item.count)
        })
        this.setState({ top_flowsOptions, top_flowsSeries});
        console.log("Top flows", top_flowsOptions, top_flowsSeries);
      }
    } catch (error) {}
  };

  getOptions = (data, colors, stroke = false, strokeColor = undefined) => {
    let options = {
      chart: {
        id: "apexchart-example",
        foreColor: "black",
        toolbar: {
          show: false
        }
      },
      stroke: {
        show: stroke,
        // curve: "smooth",
        // lineCap: "butt",
        // colors: strokeColor,
        // width: 2,
        // dashArray: 0
      },
      fill: {
        // colors: colors,
        opacity: 0.9,
        type: "gradient",
        gradient: {
          // shade: 'dark',
          type: "horizontal",
          shadeIntensity: 0.5,
          // gradientToColors: colors,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: []
        },
        image: {
          src: [],
          width: undefined,
          height: undefined
        },
        pattern: {
          style: "verticalLines",
          width: 6,
          height: 6,
          strokeWidth: 2
        }
      },
      xaxis: {
        categories: data
      }
    };
    return options;
  };

  getSeries = data => {
    let series = [
      {
        name: "series-1",
        data
      }
    ];
    return series;
  };

  fetchCharts = async () => {
    try {
      let url = apiPrefix + "line-graph";
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      console.log("Charts response", response.data.data.lineGraph);
      if (response.data) {
        this.setState({ chart: response.data.data.lineGraph });
      }
    } catch (error) {
      console.log(error);
      this.props.logoutUser();
    }
  };

  render() {
    return (
      <React.Fragment>
        <Layout {...this.props}>
          <Helmet>
            <title>{TITLE}</title>
          </Helmet>
          <div className="full-height" style={{ paddingTop: 60 }}>
            

            {this.state.chart && (
              <div className="p-3 col-md-12 mt-2 mr-2">
                <div className="card p-2">
                  <h5 className="ml-2 p-2 global_title_msf">Message Activity</h5>
                  <div className="">
                    {/* <LineChart chart={this.state.chart} /> */}
                    <Chart
                      options={this.getOptions(
                        this.state.chart ? this.state.chart.labels : null,
                        ['#546E7A', '#E91E63'],
                        true
                      )}
                      series={this.getSeries(
                        this.state.chart
                          ? this.state.chart.datasets[0].data
                          : []
                      )}
                      type="area"
                      height={320}
                    />
                  </div>
                </div>
              </div>
            )}
            {this.state.chart && (
              <div className="p-3 col-md-12 mt-2 mr-2">
                <div className="card p-2">
                  <h5 className="ml-2 p-2 global_title_msf">User Activity</h5>
                  <div className="">
                    {/* <LineChart chart={this.state.chart} /> */}
                    <Chart
                      options={this.getOptions(
                        this.state.chart ? this.state.chart.labels : null,
                        ["#314092", "#E91E63"]
                      )}
                      series={this.getSeries(
                        this.state.chart
                          ? this.state.chart.datasets[1].data
                          : []
                      )}
                      type="area"
                      height={320}
                    />
                  </div>
                </div>
              </div>
            )}
            {this.state.chart && (
              <div className="p-3 col-md-12 mt-2 mr-2">
                <div className="card p-2">
                  <h5 className="ml-2 p-2 global_title_msf">Top Flows</h5>
                  <div className="">
                    {/* <LineChart chart={this.state.chart} /> */}
                    <Chart
                      options={this.getOptions(
                        this.state.top_flowsOptions,
                        ['#546E7A', '#E91E63'],
                        true
                      )}
                      series={this.getSeries(
                        this.state.top_flowsSeries
                      )}
                      type="bar"
                      height={320}
                    />
                    {console.log("Helllo",this.state.top_flowsSeries)}
                  </div>
                </div>
              </div>
            )}
            {!this.state.chart && <span>Loading...</span>}
            <div className="p-3 col-md-12 mt-2 mr-2">
              <div className="card p-2">
                <h5 className="ml-2 p-2 global_title_msf">Topic Graph</h5>
                <div className="">
                  <PieChart
                      options={{ 
                        labels: this.state.top_flow_pie_label
                      }} 
                      series={ this.state.top_flow_pie_data }
                      type="pie"
                      height={320}
                    />
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  token: state.auth.currentUserToken
});

export default connect(mapStateToProps, { logoutUser })(Analytics);
