import React, { Component } from 'react'
import './Picture.scss'
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { apiPrefix } from "./../../../helper";
import axios from 'axios'

interface Props {
    item: any,
    addOrUpdateFlow: any,
    allItems: any,
    index: number,
}

class Picture extends React.Component<Props, { image: any }>{
    inputImage: HTMLInputElement;
    constructor(props: Props) {
        super(props)
        this.state = {
            image: this.props.item.image ? this.props.item.image : ""
        }
    }

    submitImage = () => {
        if (this.state.image) {
            let allItems: any = [...this.props.allItems]
            let picture = allItems[this.props.index]
            picture.image = this.state.image
            allItems[this.props.index] = picture
            console.log(allItems)
            this.props.addOrUpdateFlow(allItems)
        } else {
            toast.error("Please add image url or upload an image")
        }

    }

    onChangeImage = (e) => {
        e.preventDefault()
        var file: any = e.target.files[0];
        if (file) {
            this.setState({ image: URL.createObjectURL(file) })
            // const formData: any = new FormData()
            // formData.append('file', file)
            // console.log("daata image object : ", file)
            // console.log("daata image formdatas: ", formData)

            // try {
            //     let url = apiPrefix + 'upload'
            //     let headers = {
            //         'Content-Type': 'multipart/form-data',
            //         Authorization: `Bearer ${this.props.token}`
            //     }
            //     let response: any = await axios({
            //         method: 'post',
            //         url: url,
            //         headers: headers,
            //         data: {
            //             file: file
            //         }
            //     })
            //     console.log("upload response", response.data)
            //     // console.log("Image url : ", URL.createObjectURL(file))
            // } catch (error) {

            // }
        }
        this.inputImage.value = null;

    }

    render() {
        return (
            <React.Fragment>
                <div className="input_box_msg" id="remove_input_msg margin_bottom" data-toggle="modal" data-target={"#picture_modal" + this.props.item.id}>
                    <img id="img" className={this.props.item.image ? "opacity-1" :"opacity"} src={this.props.item.image} />
                    {/*<input id={"image" + this.props.item.id}  ref={input => this.inputImage = input} type="file" accept="image/*"/>*/}
                    <div className={`text box_style picture_msg ${this.props.item.image ? "opacity" :"opacity-1"}`} id="picture" >
                        {/*<i className="fa fa-picture-o" aria-hidden="true"></i>*/}
                        <img src="assets/image_1.png" />
                        <p>+ Picture</p>
                    </div>
                    <div className="delet_icon" id="delete" onClick={() => this.props.addOrUpdateFlow(this.props.allItems.filter(item => item.id !== this.props.item.id))}>
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                    </div>
                </div>


                <div className="modal fade" id={"picture_modal" + this.props.item.id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Upload Picture
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <input value={this.state.image} type="text" className="form-control modal_input" placeholder="Enter Your Picture Url" onChange={(e) => this.setState({ image: e.target.value })} />
                                </div>
                                <p className="or">OR</p>
                                <div className="video_upload_file">
                                    <input type="file" className="Modal_fileuploader modal_input" ref={input => this.inputImage = input} onChange={this.onChangeImage}/>
                                        <img src="assets/upload.png"/>
                                    <p>Upload File</p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">close</button>
                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.submitImage()}>Done</button>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    allItems: state.automation.items,
});

export default connect(mapStateToProps, null)(Picture);
