import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import Login from './components/login/Login';
import Dashboard from './components/dashboard/Dashboard';
import Analytics from './components/analytics/Analytics';
import TestAnalyze from './components/analyze/Analyze';
import Retrain from './components/retrain/Retrain';
// import TopFlows from './components/topFlows/TopFlows';
import RecentUsers from './components/recentUsers/RecentUsers';
import People from './components/people/People';
import CoronaTest from './components/corona-test/Corona';
import Automation from './components/automation/Automation';
import Auth from './components/Auth';
import RequireAuth from './components/RequireAuth'
import './App.css';
import Test from "./components/test/Test";
import Audience from "./components/audience/Audience";



class App extends Component {

  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div style={{ height: '100%' }}>

            <Switch>
                <Route exact path='/' render={props => <Dashboard {...props} />} />
                {/* <Route exact path='/analytics' render={props => <Analytics {...props} />} /> */}
                <Route exact path='/analytics/activity' render={props => <Analytics {...props} />} />
                {/* <Route exact path='/analytics/topFlows' render={props => <TopFlows {...props} />} /> */}
                <Route exact path='/analytics/recentUsers' render={props => <RecentUsers {...props} />} />
                <Route exact path='/people' render={props => <People {...props} />} />
                <Route exact path='/audience' render={props => <Audience {...props} />} />
                <Route exact path='/retrain' render={props => <Retrain {...props} />} />
                <Route exact path='/automation' render={props => <Automation {...props} />} />
                <Route path='/automation/:flow' render={props => <Automation {...props} />} />
                <Route exact path='/corona-test' render={props => <CoronaTest {...props} />} />
                <Route exact path='/test-analyze' render={props => <TestAnalyze {...props} />} />
                {/* <Route path='/test' render={props => <Test {...props} />} /> */}
            </Switch>

            {/* <Auth> */}
              <Switch>
                <Route exact path='/login' render={props => <Login {...props} />} />
              </Switch>
            {/* </Auth> */}
          </div>
        </Router>
      </Provider>
    );
  }
}



export default App;
