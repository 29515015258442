import * as React from "react";
import "./audience.scss";
import { connect } from "react-redux";
import { apiPrefix, baseTitle, TableCSVExporter } from "../../helper";
import { ToastContainer, toast } from "react-toastify";
import OutsideClick from 'detect-outside-click-react';
import { Helmet } from "react-helmet";
import axios from "axios";
import ReactLoading from "react-loading";
import Layout from "../Layout";
import { logoutUser } from "../login/actions/authActions";
import Pagination from "react-js-pagination";
// import { log } from "console";

interface Props {
  token: any;
  location: any;
  logoutUser: () => any;
  history: any;
}

interface User {
  sender_id: number | undefined;
  profile_pic: string | undefined;
  profilePic: string | undefined;
  attachmentNID: string | undefined;
  attachmentOrNumberGD: string | undefined;
  complaintCreatedDate: string | undefined;
  complaintCreatedDateHuman: string | undefined;
  complaintType: string | undefined;
  criminalAddress: string | undefined;  
  criminalName: string | undefined;
  criminalPhone: string | undefined;
  criminalSocialLink: string | undefined;
  fbName: string | undefined;
  informingOfficer: string | undefined;
  numberNID: string | undefined;
  occurrenceStatus: string | undefined;
  remark: string | undefined;
  action: string | undefined;
  unitOfficer: string | undefined;
  summeryOfComplaint: string | undefined;
  trackingNumber: string | undefined;
  victimAddress: string | undefined;
  victimName: string | undefined;
  victimPhone: string | undefined;
  first_name: string;
  last_name: string;
  session?: number;
  joining_date?: string;
  joined?: string;
  phone?: string;
}

const TITLE = `${baseTitle} Audience`;

class Audience extends React.Component<
  Props,
  {
    users: [] | null;
    chatNow: boolean;
    atribute: string | null;
    operator: string | null;
    value: string | null;
    attributes: [];
    values: [];
    operators: [];
    user: any;
    messages: [];
    data: any

  }
  > {
  constructor(props: Props) {
    super(props);
    this.state = {
      users: null,
      chatNow: false,
      atribute: null,
      operator: null,
      value: "",
      attributes: [],
      values: [],
      operators: [],
      user: null,
      messages: [],
      data: null
    };
    this.fetchUsers();
    this.getAttrAndOperators();
  }

  fetchUsers = async (page = null) => {
    try {
      let url = apiPrefix + `complaintsData${page ? page : ''}`;
      let headers = {
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      console.log("User response", response.data);
      if (response.data) {
        this.setState({
          users: response.data.data.complaints.data,
          data: response.data.data.complaints,
        });
      }
    } catch (error) {
      console.log(error);
      // this.props.logoutUser();
    }
  };

  exportToCsv = () => {
    const dataTable = document.getElementById("LeadsDataTable");

    const exporter = new TableCSVExporter(dataTable);
    const csvOutput = exporter.convertToCSV();
    const csvBlob = new Blob([csvOutput], { type: "text/csv" });
    const blobUrl = URL.createObjectURL(csvBlob);
    const anchorElement = document.createElement("a");

    anchorElement.href = blobUrl;
    anchorElement.download = "table-export.csv";
    anchorElement.click();

    setTimeout(() => {
      URL.revokeObjectURL(blobUrl);
    }, 500);
  };

  filterLeads = async () => {
    let { atribute, operator, value } = this.state;

    if (atribute && operator && value) {
      try {
        let url = apiPrefix + "filterComplaints";
        let headers = {
          Authorization: `Bearer ${this.props.token}`
        };
        let response: any = await axios({
          method: "post",
          url: url,
          headers: headers,
          data: { attributes: atribute, operators: operator, dataString: value }
        });
        console.log("Filter User response", response.data);
        if (response.data) {
          let data = response.data.data.complaints.data;
          // if(data.length > 0){
          this.setState({ users: data, data: response.data.data.complaints.data, });
          // }
        }
      } catch (error) {
        console.log("Leads filter error", error);
        // this.props.logoutUser();
      }
      return;
    }
    toast.error("Please select all field");
  };

  getAttrAndOperators = async () => {
    try {
      let url = apiPrefix + "complaintAttributes";
      let headers = {
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      let url1 = apiPrefix + "complaintOperator";
      let response1: any = await axios({
        method: "get",
        url: url1,
        headers: headers
      });
      console.log("Get attr opretor response", response.data);
      let attributes = response.data.data.attributes;
      let operators = response1.data.data.operators;
      if (attributes && operators) {
        this.setState({ attributes, operators });
      }
    } catch (error) {
      console.log(error);
      this.props.logoutUser();
    }
  };

  viewConversations = async user => {
    try {
      let url = `${apiPrefix}messages/${user.sender_id}`;
      let headers = {
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      console.log("View converstaions: ", response.data);
      if (response.data.success) {
        this.setState({ chatNow: true, messages: response.data.data.messages });
      }
    } catch (error) {
      console.log(error);
      // this.props.logoutUser();
    }
  };

  handlePagination = (page) => {
    this.fetchUsers(`?page=${page}`)
    // this.props.history.push(`?page=${page}`)
  }

  getAttrDependancy = async (e) => {
    try {
        let url = apiPrefix + e;
        let headers = {
            Authorization: `Bearer ${this.props.token}`
        };
        let response: any = await axios({
            method: "get",
            url: url,
            headers: headers
        });
        // console.log("Get attr opretor response", response.data);
        // console.log("targetAction", targetAction);

        if(e == 'actions'){
            let values = response.data.data.possibleActions;
            if (values) {
                this.setState({ values });
            }
        } else if(e == 'setStatus'){
            let values = response.data.data.crimeStatus;
            if (values) {
                this.setState({ values });
            }
        } else if(e == 'complaintsType'){
            let values = response.data.data.complaintsType;
            if (values) {
                this.setState({ values });
            }
        }        
    } catch (error) {
      console.log(error);
      this.props.logoutUser();
    }
  };
  
  updateAttributeDependancy = (e) => {            
    this.setState({ atribute: e.target.value });
    if(e.target.value == 'পুলিশ কর্তৃক গৃহীত ব্যবস্থা'){
        this.getAttrDependancy('actions');
    } else if(e.target.value == 'অভিযোগের বর্তমান ব্যবস্থা'){
        this.getAttrDependancy('setStatus');
    } else if(e.target.value == 'অভিযোগের ধরণ'){
        this.getAttrDependancy('complaintsType');
    }     
  }

  render() {
    return (
      <React.Fragment>
        <div className="full-height">
          <Layout {...this.props}>
            <Helmet>
              <title>{TITLE}</title>
            </Helmet>
            <div className="add_segment">
              <div className="segment_header">
                <button type="button" className="clear_section">
                  Female User's
                </button>
                <button type="button" className="add_segment">
                  + Add Segment
                </button>
              </div>
              <div className="segment_body">
                <h4 className="select_title">Attribute</h4>
                <div className="attr">
                  <select
                    className="selector"
                    onChange={e => this.updateAttributeDependancy(e)}
                  >
                    <option value="">Select</option>
                    {Object.values(this.state.attributes).map((item, i) => (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <h4 className="select_title">Operator</h4>
                <div className="operator">
                  <select
                    className="selector"
                    onChange={e => this.setState({ operator: e.target.value })}
                  >
                    <option value="">Select</option>
                    {Object.values(this.state.operators).map((item, i) => (
                      <option value={item} key={i}>{item}</option>
                    ))}
                  </select>
                </div>
                <h4 className="select_title">Value</h4>
                <div className="operator">
                  <select
                    className="selector"
                    onChange={e => this.setState({ value: e.target.value })}
                  >
                    <option value="">Select</option>
                    {Object.values(this.state.values).map((item, i) => (
                      <option value={item} key={i}>{item}</option>
                    ))}
                  </select>
                </div>
                {/* <h4 className="select_title">Value</h4>
                <div className="value">
                  <div className="selector">
                    <input
                      type="text"
                      className="selector"
                      value={this.state.value}
                      onChange={e => this.setState({ value: e.target.value })}
                    />
                  </div>
                </div> */}

                <div className="and">
                  <img src="assets/filter.png" />
                  <p
                    className="filter_name"
                    onClick={() => this.filterLeads()}
                    style={{ border: "none" }}
                  >
                    Filter
                  </p>
                </div>
                <div className="and ml-2">
                  <img src="assets/reset.png" />
                  <p
                    className="reset_name"
                    onClick={() => this.fetchUsers()}
                  >
                    Reset
                  </p>
                </div>
              </div>
              {/* <div className="segment_body">
                            <h4 className="select_title">Attribute</h4>
                            <div className="attr">
                                <select className="selector">
                                    <option value="volvo">Select</option>
                                    <option value="saab">First Name</option>
                                    <option value="opel">Last Name</option>
                                    <option value="audi">Sender Id</option>
                                    <option value="audi">gender</option>
                                    <option value="audi">sessions</option>
                                    <option value="audi">joingin date</option>
                                </select>
                            </div>
                            <h4 className="select_title">Operator</h4>
                            <div className="operator">
                                <select className="selector">
                                    <option value="Select">Select</option>
                                    <option value="volvo">is</option>
                                    <option value="saab">is Not</option>
                                    <option value="opel">Greater Than</option>
                                    <option value="audi">Less Than</option>
                                </select>
                            </div>
                            <h4 className="select_title">Value</h4>
                            <div className="value">
                                <div className="selector">
                                    <input type="text"  />
                                </div>
                            </div>

                            <div className="plus">
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </div>
                        </div> */}
            </div>
            <div className="new_card card">
              <div className="d-flex p_right  d-flex justify-content-end">
                {/* <div className="new_card_title">
                                <h4 className="select_title title_width">View</h4>
                                <div className="attr">
                                    <select className="selector">
                                        <option value="volvo">Select</option>
                                        <option value="saab">First Name</option>
                                        // <input type="checkbox" className="box" />
                                        <option value="opel">Last Name</option>
                                        <option value="audi">Sender Id</option>
                                        <option value="audi">gender</option>
                                        <option value="audi">sessions</option>
                                        <option value="audi">joingin date</option>
                                    </select>
                                </div>
                            </div> */}
                <div className="table_button">
                  {/* <button type="button" className="clear_section">
                    Delete
                  </button> */}
                  <img src="assets/share.png" />
                  <p className="add_segment " onClick={() => this.exportToCsv()}>
                    Export as CSV
                  </p>
                </div>
              </div>
              <div className="card-body new_card_body overflowScroll">
                <table className="table new_table" id="LeadsDataTable">
                  <thead className="people-table-head">
                    <tr className="table_head">
                      <th scope="col">
                        {" "}
                        {/* <input type="checkbox" className="box" /> */}
                        No
                      </th>
                      <th className='table_name table_head_th' scope="col">অভিযোগকারীর নাম</th>
                      <th scope="col">সংযুক্তি</th>
                      <th scope="col">কেস আইডি</th>
                      <th scope="col">অভিযোগ গ্রহন এর তারিখ</th>
                      <th scope="col">অভিযোগকারীর ফেসবুক আইডির নাম</th>
                      <th scope="col">অভিযোগকারীর ইমেইল</th>
                      <th scope="col">অভিযোগকারীর মোবাইল নং</th>
                      <th scope="col">অভিযোগকারীর ঠিকানা</th>
                      <th scope="col">অভিযোগের ধরন</th>
                      <th scope="col">অভিযোগের সারসংক্ষেপ</th>
                      <th scope="col">অভিযুক্তের নাম</th>
                      <th scope="col">অভিযুক্তের ফেসবুক  ইউআরএল</th>
                      <th scope="col">অভিযুক্তের মোবাইল নং</th>
                      <th scope="col">অভিযুক্তের ঠিকানা</th>
                      <th scope="col">MFS Info</th>
                      <th scope="col">MFS Provider</th>
                      <th scope="col">মামলা/জিডি সংক্রান্ত তথ্য</th>
                      <th scope="col">মামলা/জিডির নং</th>
                      <th scope="col">PCSW কর্তৃক গৃহীত ব্যবস্থা</th>
                      <th scope="col">জেলা/অন্য ইউনিটের তদন্তকারী অফিসার</th>
                      <th scope="col">মন্তব্য</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.users &&
                      this.state.users.map((user: User, index: number) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              name="vehicle1"
                              value="Bike"
                            />
                          </td>
                          <td className="table_item_left">                            
                            {user.victimName}
                          </td>
                          <td>{user.attachmentNID || user.attachmentOrNumberGD  ? 'Yes' : "No"}</td>
                          <td className="table_item_left">                            
                            {user.trackingNumber}
                          </td>
                          <td className="table_item_left">                            
                            {user.complaintCreatedDate}
                          </td>
                          <td className="table_item_left">     
                            <img
                              className="peoples_img"
                              src={user.profilePic}
                            />{" "}                       
                            {user.fbName}
                          </td>
                          <td>{"N/A"}</td>
                          <td>{user.victimPhone ? user.victimPhone : "N/A"}</td>
                          <td>{user.victimAddress ? user.victimAddress : "N/A"}</td>
                          <td>{user.complaintType ? user.complaintType : "N/A"}</td>
                          <td>{user.summeryOfComplaint ? user.summeryOfComplaint : "N/A"}</td>
                          <td>{user.criminalName ? user.criminalName : "N/A"}</td>
                          <td>{user.criminalSocialLink ? user.criminalSocialLink : "N/A"}</td>
                          <td>{user.criminalPhone ? user.criminalPhone : "N/A"}</td>
                          <td>{user.criminalAddress ? user.criminalAddress : "N/A"}</td>
                          <td>{"N/A"}</td>
                          <td>{"N/A"}</td>
                          <td>{user.complaintType ? user.complaintType : "N/A"}</td>                          
                          <td className="table_item_left">                            
                            {user.trackingNumber}
                          </td>
                          <td>{user.informingOfficer ? user.informingOfficer : "N/A"}</td>
                          <td>{user.unitOfficer ? user.unitOfficer : "N/A"}</td>
                          <td>
                            <a href="#">View Details</a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                {this.state.users && !(this.state.users.length > 0) && (
                  <div className="alert alert-danger text-center" role="alert">
                    Sorry, No data found
                  </div>
                )}
                <div className="d-flex justify-content-center">
                  {!this.state.users && (
                    <ReactLoading
                      type={"bars"}
                      color={"#01a0fb"}
                      height={"10%"}
                      width={"10%"}
                    />
                  )}
                </div>
              </div>
              {this.state.users && this.state.data &&
                <div className="d-flex justify-content-center">
                  <Pagination
                    activePage={this.state.data.current_page}
                    itemsCountPerPage={this.state.data.per_page}
                    totalItemsCount={this.state.data.total}
                    pageRangeDisplayed={10}
                    onChange={this.handlePagination}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              }
            </div>

            {this.state.user && (
              <OutsideClick close={() => this.setState({ chatNow: false })}>
                <div
                  onBlur={() => console.log('Blured chat')}
                  className={`chat_now ${this.state.chatNow ? " " : "chat_hide"}`}
                >
                  <div className="inner_chat">
                    <div className="chat_now_header">
                      <div className="chat_now_image">
                        <img src={this.state.user.profile_pic} />
                      </div>
                      <div className="chat_now_image_name">
                        <p>
                          {" "}
                          {this.state.user.first_name} {this.state.user.last_name}
                        </p>
                      </div>
                      <div
                        className="chat_now_image_close"
                        onClick={() => this.setState({ chatNow: false })}
                      >
                        x
                    </div>
                    </div>
                    <div className="chat_now_body">
                      {this.state.messages.map((message: any, index) => (
                        <React.Fragment >
                          {message.message && (
                            <div className="chat_right" key={index}>
                              <div className="chat_right_txt">
                                <p>{message.message}</p>
                              </div>
                              <div className="chat_right_icon">
                                <img src={this.state.user.profile_pic} />
                              </div>
                            </div>
                          )}
                          {message.response && (
                            <div className="chat_left">
                              <div className="chat_left_icon">
                                <img src="assets/avatar.png" />
                              </div>
                              <div className="chat_left_txt">
                                <p>{message.response}</p>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                    {/* <div className="chat_now_footer">
                  <input type="text" placeholder="type a message" />
                  <div className="sent">
                    <img src="assets/sent.png" />
                  </div>
                </div> */}
                  </div>
                </div>


              </OutsideClick>
            )}

          </Layout>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  token: state.auth.currentUserToken
});

export default connect(mapStateToProps, { logoutUser })(Audience);
