import React, { Component } from 'react'
import { connect } from "react-redux";
import '../file/File.scss'

interface Props {
    item: any,
    addOrUpdateFlow: any,
    allItems: any,
}

class File extends React.Component <Props>{
    constructor(props: Props) {
        super(props)
        this.state = {
        }
    }
    render() {
        return (
            <React.Fragment>
                <div >
                    <div className="move text box_style file append_box" id="file" data-toggle="modal" data-target={"#file_1"+this.props.item.id}>
                        {/*<input type="file" id="file_upload" multiple required />*/}
                        <br />
                        {/*<i className="fa fa-align-left" aria-hidden="true"></i>*/}
                        <img src="assets/file.png" />
                        <p>+File<span id="file_name">: </span></p>
                        <br />
                        <div id="file-upload-filename"></div>
                        <div className="delet_icon_gallery" id="gallery_delete" onClick={() => this.props.addOrUpdateFlow(this.props.allItems.filter(item => item.id !== this.props.item.id))}>
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id={"file_1"+this.props.item.id}  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Upload file
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Enter Your File Url" />
                                </div>
                                <p className="or">OR</p>
                                <div className="video_upload_file">
                                    <input type="file" className="Modal_fileuploader" />
                                    <p>Upload Your File</p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Delete</button>
                                <button type="button" className="btn btn-primary">Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    allItems: state.automation.items,
});

export default connect(mapStateToProps, null)(File);
