import React, { Component } from 'react'
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import '../video/video.scss';
import ReactPlayer from 'react-player'

interface Props {
    item: any,
    addOrUpdateFlow: any,
    allItems: any,
    index: number,
}

class Video extends React.Component<Props, { video: any }> {
    constructor(props: Props) {
        super(props)
        this.state = {
            video: this.props.item.video ? this.props.item.video : ""
        }
    }

    submitVideo = () => {
        if (this.state.video) {
            let allItems: any = [...this.props.allItems]
            let videoFile = allItems[this.props.index]
            videoFile.video = this.state.video
            allItems[this.props.index] = videoFile
            console.log(allItems)
            this.props.addOrUpdateFlow(allItems)
        } else {
            toast.error("Please add video url or upload an video")
        }

    }

    render() {
        return (
            <React.Fragment>
                <div className="move text box_style video append_box video_animation" id="video" data-toggle="modal" data-target={"#videoModal" + this.props.item.id}>
                    <input type="file" className="fileuploader" style={{ display: 'none' }} />
                    <button className="fileuploader-btn">Select a Video File</button>
                    <br />
                    {/*<i className="fa fa-youtube-play" aria-hidden="true"></i>*/}
                    <img src="assets/video.png" />
                    <p style={{ marginBottom: 30 }}>+Video</p>
                    <div className="delet_icon_gallery" id="gallery_delete" onClick={() => this.props.addOrUpdateFlow(this.props.allItems.filter(item => item.id !== this.props.item.id))}>
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                    </div>
                    <div className="videoPlayer ">
                        <ReactPlayer
                            muted
                            controls={true}
                            url={this.props.item.video}
                            playing={false}
                            height={'100%'}
                            width={'100%'}
                        />
                    </div>
                </div>


                <div className="modal fade" id={"videoModal" + this.props.item.id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Upload video
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <input
                                        onChange={(e) => this.setState({ video: e.target.value })}
                                        value={this.state.video}
                                        type="text"
                                        className="form-control modal_input"
                                        placeholder="Enter video url with .mp4 format."
                                    />
                                </div>
                                <p className="or">OR</p>
                                <div className="video_upload_file">
                                    <input type="file" className="Modal_fileuploader" />
                                    <img src="assets/upload.png"/>
                                    <p>Upload File</p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.submitVideo()}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    allItems: state.automation.items,
});

export default connect(mapStateToProps, null)(Video);
