import React, { Component } from "react";
import  Header  from './header/Header';
import Sidebar from './sidebar/Sidebar';
import RequireAuth from './RequireAuth'
import { RouteComponentProps } from 'react-router-dom';

interface Props {
    location: any,
    match?: RouteComponentProps
}


class Layout extends Component<Props>{
    constructor(props: Props) {
        super(props)
        this.state = {

        }
        console.log("Match from router: ",this.props.match)
    }

    render() {
        return (
            <React.Fragment>
                <Header {...this.props} />
                <Sidebar {...this.props} />
                <RequireAuth>
                    <div className="main-box">
                        {this.props.children}
                    </div>
                </RequireAuth>
            </React.Fragment>
        );
    }
}

export default Layout;