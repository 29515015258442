import { LOGIN, LOGOUT, REGISTER } from "../actions/types";

let initialState = {
  currentUser: [],
  currentUserToken: ''
}

export default function (state = initialState, action: any) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        // currentUser: action.payload,
        currentUserToken: action.token
      };
    case REGISTER:
      return {
        ...state,
        currentUser: action.payload,
        currentUserToken: action.token
      };
    case LOGOUT:
      return {
        ...state,
        currentUserToken: '',
        currentUser: []
      };

    default:
      return state;
  }
}
