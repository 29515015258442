import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiPrefix, baseTitle } from "../../helper";
import { Helmet } from "react-helmet";
import LineChart from "../linechart/LineChart";
import ReactWeather from "react-open-weather";
import "react-open-weather/lib/css/ReactWeather.css";
import { connect } from "react-redux";
import Layout from "../Layout";
import { logoutUser } from "../login/actions/authActions";

interface Props {
  token: any;
  location: any;
  logoutUser: () => any;
}

const TITLE = `${baseTitle} Analytics`;

class RecentUsers extends Component<Props, { chart: [] }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      chart: []
    };
    this.fetchCharts();
  }

  fetchCharts = async () => {
    try {
      let url = apiPrefix + "line-graph";
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      console.log("Charts response", response.data);
      if (response.data) {
        this.setState({ chart: response.data.data.lineGraph });
      }
    } catch (error) {
      console.log(error);
      this.props.logoutUser();
    }
  };

  render() {
    return (
      <React.Fragment>
        <Layout {...this.props}>
          <Helmet>
            <title>{TITLE}</title>
          </Helmet>
         
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  token: state.auth.currentUserToken
});

export default connect(mapStateToProps, { logoutUser })(RecentUsers);
