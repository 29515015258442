import React, { Component } from "react";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import "./Picker.scss";
import OutsideClickHandler from 'react-outside-click-handler';


export default class EmojiPicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pickEmoji: false
    }
  }

  render() {
    return (
      <OutsideClickHandler
        onOutsideClick={() => this.setState({pickEmoji: false})}
      >
      
          <div className={"iconContainer"} style={this.props.containerStyle} onClick={() => this.setState({ pickEmoji: !this.state.pickEmoji })}>
            <img
              src="https://img.icons8.com/android/24/000000/happy.png"
              alt="pick emogi"
              style= {this.props.pickerIconStyle}
              className={"picker-icon"}
            />
            <p className="text-success text-limit text-limit" style={this.props.limitTxtStyle}>{(this.props.limit - (this.props.text ? this.props.text.length : 0))}</p>
          </div>
          {this.state.pickEmoji && <Picker
            onSelect={this.props.addEmoji}
            style={{ position: "absolute", zIndex: 999, left: 0 }}
          />}
     
      </OutsideClickHandler>
    );
  }
}
