import { CHANGE_ITEMS, DELETE_ITEM } from "../actions/types";
import { apiPrefix } from "./../../../helper"
import axios from 'axios'
import qs from 'qs'

let initialState = {
  items: [],
}

export default function (state = initialState, action: any) {
  switch (action.type) {
    case CHANGE_ITEMS:
      return {
        ...state,
        items: action.payload,
      };
    case DELETE_ITEM:
      let newItems = state.items.filter(item => item.id !== action.payload)
          return {
            ...state,
            items: newItems,
          };
    

    default:
      return state;
  }
}
